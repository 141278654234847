"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Pharmacy API
 * API for pharmacy management
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.TreeNodeType = exports.TimeRangeType = exports.PolicyType = exports.DistanceUnits = exports.ContainerType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.ContainerType = {
    Tier: 'tier',
    Location: 'location'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.DistanceUnits = {
    Miles: 'miles',
    Kilometers: 'kilometers'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.PolicyType = {
    PrivacyPolicy: 'privacy_policy',
    TermsOfService: 'terms_of_service'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TimeRangeType = {
    Normal: 'normal',
    Special: 'special'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.TreeNodeType = {
    Brand: 'brand',
    Location: 'location',
    Tier: 'tier'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createDepartmentDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentCreate', 'createDepartmentDto', createDepartmentDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createDepartmentDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindAll', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentFindOne', 'deptId', deptId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursCreate', 'departmentId', departmentId);
                    // verify required parameter 'createNormalTimeRangeDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursCreate', 'createNormalTimeRangeDto', createNormalTimeRangeDto);
                    localVarPath = "/departments/{department_id}/hours"
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createNormalTimeRangeDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentHoursFindAll', 'departmentId', departmentId);
                    localVarPath = "/departments/{department_id}/hours"
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentRemove', 'deptId', deptId);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'deptId' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'deptId', deptId);
                    // verify required parameter 'updateDepartmentDto' is not null or undefined
                    (0, common_1.assertParamExists)('departmentUpdate', 'updateDepartmentDto', updateDepartmentDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/departments/{dept_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("dept_id", "}"), encodeURIComponent(String(deptId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateDepartmentDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createExternalReferenceDto' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyCreate', 'createExternalReferenceDto', createExternalReferenceDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/id"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createExternalReferenceDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'externalSource' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyFindOne', 'externalSource', externalSource);
                    // verify required parameter 'externalId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyFindOne', 'externalId', externalId);
                    localVarPath = "/pharmacies/id/{external_source}/{external_id}"
                        .replace("{".concat("external_source", "}"), encodeURIComponent(String(externalSource)))
                        .replace("{".concat("external_id", "}"), encodeURIComponent(String(externalId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationCreate', 'locationId', locationId);
                    // verify required parameter 'createExternalReferenceDto' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationCreate', 'createExternalReferenceDto', createExternalReferenceDto);
                    localVarPath = "/locations/{location_id}/id"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createExternalReferenceDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'externalSource' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationFindOne', 'externalSource', externalSource);
                    // verify required parameter 'externalId' is not null or undefined
                    (0, common_1.assertParamExists)('externalReferencePharmacyLocationFindOne', 'externalId', externalId);
                    localVarPath = "/locations/id/{external_source}/{external_id}"
                        .replace("{".concat("external_source", "}"), encodeURIComponent(String(externalSource)))
                        .replace("{".concat("external_id", "}"), encodeURIComponent(String(externalId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createParentCompanyDto' is not null or undefined
                    (0, common_1.assertParamExists)('parentCompanyCreate', 'createParentCompanyDto', createParentCompanyDto);
                    localVarPath = "/parent_companies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createParentCompanyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'search' is not null or undefined
                    (0, common_1.assertParamExists)('parentCompanySearch', 'search', search);
                    localVarPath = "/parent_companies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (search !== undefined) {
                        localVarQueryParameter['search'] = search;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingCreatePharmacyBranding', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingCreatePharmacyBranding', 'createBrandingDto', createBrandingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingDeletePharmacyBranding', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingFindOne', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingUpdatePharmacyBranding', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updateBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyBrandingUpdatePharmacyBranding', 'updateBrandingDto', updateBrandingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/branding"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createPharmacyDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyCreate', 'createPharmacyDto', createPharmacyDto);
                    localVarPath = "/pharmacies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyDelete', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pharmacies";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindHierarchy: function (pharmacyId, nodeId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyFindHierarchy', 'pharmacyId', pharmacyId);
                    // verify required parameter 'nodeId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyFindHierarchy', 'nodeId', nodeId);
                    localVarPath = "/pharmacies/{pharmacy_id}/hierarchy/{node_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("node_id", "}"), encodeURIComponent(String(nodeId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyFindOne', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingCreateLocationBranding', 'locationId', locationId);
                    // verify required parameter 'createBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingCreateLocationBranding', 'createBrandingDto', createBrandingDto);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingDeletePharmacyBranding', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingUpdatePharmacyBranding', 'locationId', locationId);
                    // verify required parameter 'updateBrandingDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationBrandingUpdatePharmacyBranding', 'updateBrandingDto', updateBrandingDto);
                    localVarPath = "/locations/{location_id}/branding"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateBrandingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createPharmacyLocationDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationCreatePharmacyLocation', 'createPharmacyLocationDto', createPharmacyLocationDto);
                    localVarPath = "/locations";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPharmacyLocationDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationDelete', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} [sortBy]
         * @param {string} [postalCode]
         * @param {string} [lat]
         * @param {string} [lng]
         * @param {string} [units]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (sortBy, postalCode, lat, lng, units, page, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/locations";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (sortBy !== undefined) {
                        localVarQueryParameter['sort_by'] = sortBy;
                    }
                    if (postalCode !== undefined) {
                        localVarQueryParameter['postal_code'] = postalCode;
                    }
                    if (lat !== undefined) {
                        localVarQueryParameter['lat'] = lat;
                    }
                    if (lng !== undefined) {
                        localVarQueryParameter['lng'] = lng;
                    }
                    if (units !== undefined) {
                        localVarQueryParameter['units'] = units;
                    }
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'locationId', locationId);
                    // verify required parameter 'departmentId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'departmentId', departmentId);
                    // verify required parameter 'createNormalHoursDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursCreate', 'createNormalHoursDto', createNormalHoursDto);
                    localVarPath = "/locations/{location_id}/departments/{department_id}/hours"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("department_id", "}"), encodeURIComponent(String(departmentId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createNormalHoursDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationHoursFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/hours"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetLanguages: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsGetLanguages', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/languages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetServices: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsGetServices', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/services"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertLanguages: function (locationId, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsUpsertLanguages', 'locationId', locationId);
                    // verify required parameter 'requestBody' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsUpsertLanguages', 'requestBody', requestBody);
                    localVarPath = "/locations/{location_id}/languages"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertServices: function (locationId, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsUpsertServices', 'locationId', locationId);
                    // verify required parameter 'requestBody' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationOptionsUpsertServices', 'requestBody', requestBody);
                    localVarPath = "/locations/{location_id}/services"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationUpdate', 'locationId', locationId);
                    // verify required parameter 'updatePharmacyLocationDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyLocationUpdate', 'updatePharmacyLocationDto', updatePharmacyLocationDto);
                    localVarPath = "/locations/{location_id}"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyLocationDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetBuyingGroups: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pharmacies/options/buying-groups";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetWholesalers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/pharmacies/options/wholesalers";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'updatePharmacyDto' is not null or undefined
                    (0, common_1.assertParamExists)('pharmacyUpdate', 'updatePharmacyDto', updatePharmacyDto);
                    localVarPath = "/pharmacies/{pharmacy_id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePharmacyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('policyCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createPolicyDto' is not null or undefined
                    (0, common_1.assertParamExists)('policyCreate', 'createPolicyDto', createPolicyDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/policies"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPolicyDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('policyFindForPharmacy', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/policies"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {CreateTierDto} createTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierCreate: function (createTierDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'createTierDto' is not null or undefined
                    (0, common_1.assertParamExists)('tierCreate', 'createTierDto', createTierDto);
                    localVarPath = "/tiers";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createTierDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {string} [containerType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindAll: function (page, containerType, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/tiers";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (page !== undefined) {
                        localVarQueryParameter['page'] = page;
                    }
                    if (containerType !== undefined) {
                        localVarQueryParameter['containerType'] = containerType;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindOne: function (tierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tierId' is not null or undefined
                    (0, common_1.assertParamExists)('tierFindOne', 'tierId', tierId);
                    localVarPath = "/tiers/{tier_id}"
                        .replace("{".concat("tier_id", "}"), encodeURIComponent(String(tierId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierRemove: function (tierId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tierId' is not null or undefined
                    (0, common_1.assertParamExists)('tierRemove', 'tierId', tierId);
                    localVarPath = "/tiers/{tier_id}"
                        .replace("{".concat("tier_id", "}"), encodeURIComponent(String(tierId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {UpdateTierDto} updateTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierUpdate: function (tierId, updateTierDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tierId' is not null or undefined
                    (0, common_1.assertParamExists)('tierUpdate', 'tierId', tierId);
                    // verify required parameter 'updateTierDto' is not null or undefined
                    (0, common_1.assertParamExists)('tierUpdate', 'updateTierDto', updateTierDto);
                    localVarPath = "/tiers/{tier_id}"
                        .replace("{".concat("tier_id", "}"), encodeURIComponent(String(tierId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateTierDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentCreate(pharmacyId, createDepartmentDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentFindAll(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentFindOne(pharmacyId, deptId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentHoursCreate(departmentId, createNormalTimeRangeDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentHoursFindAll(departmentId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentRemove(pharmacyId, deptId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyFindOne(externalSource, externalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalReferencePharmacyLocationFindOne(externalSource, externalId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.parentCompanyCreate(createParentCompanyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.parentCompanySearch(search, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingDeletePharmacyBranding(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingFindOne(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyCreate(createPharmacyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyDelete(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyFindAll(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindHierarchy: function (pharmacyId, nodeId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyFindHierarchy(pharmacyId, nodeId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyFindOne(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingDeletePharmacyBranding(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingFindOne(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationDelete(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} [sortBy]
         * @param {string} [postalCode]
         * @param {string} [lat]
         * @param {string} [lng]
         * @param {string} [units]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (sortBy, postalCode, lat, lng, units, page, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationFind(sortBy, postalCode, lat, lng, units, page, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationFindOne(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationHoursFindAll(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetLanguages: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationOptionsGetLanguages(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetServices: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationOptionsGetServices(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertLanguages: function (locationId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationOptionsUpsertLanguages(locationId, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertServices: function (locationId, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationOptionsUpsertServices(locationId, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetBuyingGroups: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyOptionsGetBuyingGroups(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetWholesalers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyOptionsGetWholesalers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.pharmacyUpdate(pharmacyId, updatePharmacyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.policyCreate(pharmacyId, createPolicyDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.policyFindForPharmacy(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {CreateTierDto} createTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierCreate: function (createTierDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tierCreate(createTierDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {string} [containerType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindAll: function (page, containerType, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tierFindAll(page, containerType, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindOne: function (tierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tierFindOne(tierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierRemove: function (tierId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tierRemove(tierId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} tierId
         * @param {UpdateTierDto} updateTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierUpdate: function (tierId, updateTierDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.tierUpdate(tierId, updateTierDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateDepartmentDto} createDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentCreate: function (pharmacyId, createDepartmentDto, options) {
            return localVarFp.departmentCreate(pharmacyId, createDepartmentDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindAll: function (pharmacyId, options) {
            return localVarFp.departmentFindAll(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentFindOne: function (pharmacyId, deptId, options) {
            return localVarFp.departmentFindOne(pharmacyId, deptId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} departmentId
         * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursCreate: function (departmentId, createNormalTimeRangeDto, options) {
            return localVarFp.departmentHoursCreate(departmentId, createNormalTimeRangeDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} departmentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentHoursFindAll: function (departmentId, options) {
            return localVarFp.departmentHoursFindAll(departmentId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentRemove: function (pharmacyId, deptId, options) {
            return localVarFp.departmentRemove(pharmacyId, deptId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} deptId
         * @param {UpdateDepartmentDto} updateDepartmentDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        departmentUpdate: function (pharmacyId, deptId, updateDepartmentDto, options) {
            return localVarFp.departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyCreate: function (pharmacyId, createExternalReferenceDto, options) {
            return localVarFp.externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyFindOne: function (externalSource, externalId, options) {
            return localVarFp.externalReferencePharmacyFindOne(externalSource, externalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateExternalReferenceDto} createExternalReferenceDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationCreate: function (locationId, createExternalReferenceDto, options) {
            return localVarFp.externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} externalSource
         * @param {string} externalId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalReferencePharmacyLocationFindOne: function (externalSource, externalId, options) {
            return localVarFp.externalReferencePharmacyLocationFindOne(externalSource, externalId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateParentCompanyDto} createParentCompanyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanyCreate: function (createParentCompanyDto, options) {
            return localVarFp.parentCompanyCreate(createParentCompanyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} search
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentCompanySearch: function (search, options) {
            return localVarFp.parentCompanySearch(search, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingCreatePharmacyBranding: function (pharmacyId, createBrandingDto, options) {
            return localVarFp.pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingDeletePharmacyBranding: function (pharmacyId, options) {
            return localVarFp.pharmacyBrandingDeletePharmacyBranding(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingFindOne: function (pharmacyId, options) {
            return localVarFp.pharmacyBrandingFindOne(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyBrandingUpdatePharmacyBranding: function (pharmacyId, updateBrandingDto, options) {
            return localVarFp.pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePharmacyDto} createPharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyCreate: function (createPharmacyDto, options) {
            return localVarFp.pharmacyCreate(createPharmacyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyDelete: function (pharmacyId, options) {
            return localVarFp.pharmacyDelete(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindAll: function (options) {
            return localVarFp.pharmacyFindAll(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindHierarchy: function (pharmacyId, nodeId, options) {
            return localVarFp.pharmacyFindHierarchy(pharmacyId, nodeId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyFindOne: function (pharmacyId, options) {
            return localVarFp.pharmacyFindOne(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {CreateBrandingDto} createBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingCreateLocationBranding: function (locationId, createBrandingDto, options) {
            return localVarFp.pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingDeletePharmacyBranding: function (locationId, options) {
            return localVarFp.pharmacyLocationBrandingDeletePharmacyBranding(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingFindOne: function (locationId, options) {
            return localVarFp.pharmacyLocationBrandingFindOne(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateBrandingDto} updateBrandingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationBrandingUpdatePharmacyBranding: function (locationId, updateBrandingDto, options) {
            return localVarFp.pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationCreatePharmacyLocation: function (createPharmacyLocationDto, options) {
            return localVarFp.pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationDelete: function (locationId, options) {
            return localVarFp.pharmacyLocationDelete(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} [sortBy]
         * @param {string} [postalCode]
         * @param {string} [lat]
         * @param {string} [lng]
         * @param {string} [units]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFind: function (sortBy, postalCode, lat, lng, units, page, options) {
            return localVarFp.pharmacyLocationFind(sortBy, postalCode, lat, lng, units, page, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationFindOne: function (locationId, options) {
            return localVarFp.pharmacyLocationFindOne(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {string} departmentId
         * @param {CreateNormalHoursDto} createNormalHoursDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursCreate: function (locationId, departmentId, createNormalHoursDto, options) {
            return localVarFp.pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationHoursFindAll: function (locationId, options) {
            return localVarFp.pharmacyLocationHoursFindAll(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetLanguages: function (locationId, options) {
            return localVarFp.pharmacyLocationOptionsGetLanguages(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsGetServices: function (locationId, options) {
            return localVarFp.pharmacyLocationOptionsGetServices(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertLanguages: function (locationId, requestBody, options) {
            return localVarFp.pharmacyLocationOptionsUpsertLanguages(locationId, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationOptionsUpsertServices: function (locationId, requestBody, options) {
            return localVarFp.pharmacyLocationOptionsUpsertServices(locationId, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyLocationUpdate: function (locationId, updatePharmacyLocationDto, options) {
            return localVarFp.pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetBuyingGroups: function (options) {
            return localVarFp.pharmacyOptionsGetBuyingGroups(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyOptionsGetWholesalers: function (options) {
            return localVarFp.pharmacyOptionsGetWholesalers(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {UpdatePharmacyDto} updatePharmacyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pharmacyUpdate: function (pharmacyId, updatePharmacyDto, options) {
            return localVarFp.pharmacyUpdate(pharmacyId, updatePharmacyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {CreatePolicyDto} createPolicyDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyCreate: function (pharmacyId, createPolicyDto, options) {
            return localVarFp.policyCreate(pharmacyId, createPolicyDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policyFindForPharmacy: function (pharmacyId, options) {
            return localVarFp.policyFindForPharmacy(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {CreateTierDto} createTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierCreate: function (createTierDto, options) {
            return localVarFp.tierCreate(createTierDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} [page]
         * @param {string} [containerType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindAll: function (page, containerType, options) {
            return localVarFp.tierFindAll(page, containerType, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierFindOne: function (tierId, options) {
            return localVarFp.tierFindOne(tierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierRemove: function (tierId, options) {
            return localVarFp.tierRemove(tierId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} tierId
         * @param {UpdateTierDto} updateTierDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tierUpdate: function (tierId, updateTierDto, options) {
            return localVarFp.tierUpdate(tierId, updateTierDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateDepartmentDto} createDepartmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentCreate = function (pharmacyId, createDepartmentDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentCreate(pharmacyId, createDepartmentDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentFindAll = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentFindAll(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentFindOne = function (pharmacyId, deptId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentFindOne(pharmacyId, deptId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} departmentId
     * @param {Array<CreateNormalTimeRangeDto>} createNormalTimeRangeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentHoursCreate = function (departmentId, createNormalTimeRangeDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentHoursCreate(departmentId, createNormalTimeRangeDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} departmentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentHoursFindAll = function (departmentId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentHoursFindAll(departmentId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentRemove = function (pharmacyId, deptId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentRemove(pharmacyId, deptId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} deptId
     * @param {UpdateDepartmentDto} updateDepartmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.departmentUpdate = function (pharmacyId, deptId, updateDepartmentDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).departmentUpdate(pharmacyId, deptId, updateDepartmentDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateExternalReferenceDto} createExternalReferenceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyCreate = function (pharmacyId, createExternalReferenceDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyCreate(pharmacyId, createExternalReferenceDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} externalSource
     * @param {string} externalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyFindOne = function (externalSource, externalId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyFindOne(externalSource, externalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateExternalReferenceDto} createExternalReferenceDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyLocationCreate = function (locationId, createExternalReferenceDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyLocationCreate(locationId, createExternalReferenceDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} externalSource
     * @param {string} externalId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.externalReferencePharmacyLocationFindOne = function (externalSource, externalId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).externalReferencePharmacyLocationFindOne(externalSource, externalId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateParentCompanyDto} createParentCompanyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.parentCompanyCreate = function (createParentCompanyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).parentCompanyCreate(createParentCompanyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} search
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.parentCompanySearch = function (search, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).parentCompanySearch(search, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateBrandingDto} createBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingCreatePharmacyBranding = function (pharmacyId, createBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingCreatePharmacyBranding(pharmacyId, createBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingDeletePharmacyBranding = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingDeletePharmacyBranding(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingFindOne = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingFindOne(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdateBrandingDto} updateBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyBrandingUpdatePharmacyBranding = function (pharmacyId, updateBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyBrandingUpdatePharmacyBranding(pharmacyId, updateBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreatePharmacyDto} createPharmacyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyCreate = function (createPharmacyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyCreate(createPharmacyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyDelete = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyDelete(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyFindAll = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyFindAll(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} nodeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyFindHierarchy = function (pharmacyId, nodeId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyFindHierarchy(pharmacyId, nodeId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyFindOne = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyFindOne(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {CreateBrandingDto} createBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingCreateLocationBranding = function (locationId, createBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingCreateLocationBranding(locationId, createBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingDeletePharmacyBranding = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingDeletePharmacyBranding(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingFindOne = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingFindOne(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdateBrandingDto} updateBrandingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationBrandingUpdatePharmacyBranding = function (locationId, updateBrandingDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationBrandingUpdatePharmacyBranding(locationId, updateBrandingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreatePharmacyLocationDto} createPharmacyLocationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationCreatePharmacyLocation = function (createPharmacyLocationDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationCreatePharmacyLocation(createPharmacyLocationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationDelete = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationDelete(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} [sortBy]
     * @param {string} [postalCode]
     * @param {string} [lat]
     * @param {string} [lng]
     * @param {string} [units]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationFind = function (sortBy, postalCode, lat, lng, units, page, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationFind(sortBy, postalCode, lat, lng, units, page, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationFindOne = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationFindOne(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {string} departmentId
     * @param {CreateNormalHoursDto} createNormalHoursDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursCreate = function (locationId, departmentId, createNormalHoursDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursCreate(locationId, departmentId, createNormalHoursDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationHoursFindAll = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationHoursFindAll(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationOptionsGetLanguages = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationOptionsGetLanguages(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationOptionsGetServices = function (locationId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationOptionsGetServices(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationOptionsUpsertLanguages = function (locationId, requestBody, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationOptionsUpsertLanguages(locationId, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationOptionsUpsertServices = function (locationId, requestBody, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationOptionsUpsertServices(locationId, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdatePharmacyLocationDto} updatePharmacyLocationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyLocationUpdate = function (locationId, updatePharmacyLocationDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyLocationUpdate(locationId, updatePharmacyLocationDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyOptionsGetBuyingGroups = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyOptionsGetBuyingGroups(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyOptionsGetWholesalers = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyOptionsGetWholesalers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {UpdatePharmacyDto} updatePharmacyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.pharmacyUpdate = function (pharmacyId, updatePharmacyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).pharmacyUpdate(pharmacyId, updatePharmacyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {CreatePolicyDto} createPolicyDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.policyCreate = function (pharmacyId, createPolicyDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).policyCreate(pharmacyId, createPolicyDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.policyFindForPharmacy = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).policyFindForPharmacy(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {CreateTierDto} createTierDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tierCreate = function (createTierDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tierCreate(createTierDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} [page]
     * @param {string} [containerType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tierFindAll = function (page, containerType, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tierFindAll(page, containerType, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tierFindOne = function (tierId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tierFindOne(tierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tierRemove = function (tierId, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tierRemove(tierId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} tierId
     * @param {UpdateTierDto} updateTierDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.tierUpdate = function (tierId, updateTierDto, options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).tierUpdate(tierId, updateTierDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
